
.modal-claim .ant-modal-content {
  padding: 0;
  box-shadow: 8px 8px 0px 0px #000;
  border-radius: 27px;
  overflow: hidden;
}
.modal-claim .ant-modal-header {
  border-bottom: 2px solid #E9E9E9;
  padding: 9px 12px;
  margin-bottom: 0;
}
.modal-claim .ant-modal-title {
  color: #E72D36;
  text-align: center;
  font-size: 20px;
  font-family: iCiel;
}
.modal-claim .ant-modal-body {
  background: linear-gradient(180deg, #FFA7D1 0%, #FFD3AA 59.93%, #FFFEFC 100%);
  padding: 30px 20px 20px;
}